import Html from "../asset/html.png";
import Css from "../asset/css.png";
import Javascript from "../asset/javascript.png";
import Firebase from "../asset/firebase.png";
import Github from "../asset/github.png";
import React from "../asset/react.png";
import Tailwind from "../asset/tailwind.png";
import Reactlogo from "../asset/react.png";
import Sass from "../asset/sass.png";
const Skills = () => {
  return (
    <div name="skills" className="bg-[#0a193f] text-gray-300 w-full h-screen ">
      <div className="max-w-[1000px] mx-auto py-8 px-4 flex flex-col justify-center h-full">
        <div>
          <p className="text-4xl font-bold inline border-b-4  border-pink-600">
            Skills
          </p>
          <p className="py-2">These are the technologies I've worked with</p>
        </div>

        <div className="w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8">
          <div className="hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={Html} alt="HTML icon" />
            <p className="my-4">HTML</p>
          </div>
          <div className="hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={Css} alt="Css icon" />
            <p className="my-4">CSS</p>
          </div>
          <div className="hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={Javascript} alt="JS icon" />
            <p className="my-4">Javascript</p>
          </div>
          <div className="hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={Reactlogo} alt="React icon" />
            <p className="my-4">React</p>
          </div>
          <div className="hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={Tailwind} alt="Tailwind icon" />
            <p className="my-4">Tailwind</p>
          </div>
          <div className="hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={Firebase} alt="Firebase icon" />
            <p className="my-4">Firebase</p>
          </div>
          <div className="hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={Github} alt="Firebase icon" />
            <p className="my-4">Github</p>
          </div>
          <div className="hover:scale-110 duration-500 ">
            <img className="w-20   mx-auto" src={Sass} alt="Firebase icon" />
            <p className="my-10">Sass</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
