import React from "react";
import Easybank from "../asset/easybank1.JPG";
import Advice from "../asset/advice.JPG";
import FoodApp from "../asset/shoppingcart.JPG";
import MealApp from "../asset/mealproject.JPG";
const Work = () => {
  return (
    <div name="work" className="w-full md:h-screen text-gray-300 bg-[#0a192f]">
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div className="pb-8 pt-16">
          <p className=" text-4xl font-bold inline border-b-4 border-pink-600">
            Project
          </p>
          <p className="py-6">Check out some of my recent work</p>
        </div>
        {/* grid container */}
        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4 ">
          {/* grid item */}
          <div>
            <div
              style={{ backgroundImage: `url(${Easybank})` }}
              className=" group container rounded-md flex justify-center items-center mx-auto content-div "
            >
              {/* Hover effect */}

              <div className="opacity-0 group-hover:opacity-100">
                <span className="text-2xl font-bold text-white tracking-wider ">
                  Easybank website
                </span>
                <div className="pt-8 text-center">
                  <a href="https://easybank-bvn.pages.dev/">
                    <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                      Demo
                    </button>
                  </a>
                  <a href="/">
                    <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                      Code
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div className="flex flex-row space-x-2 text-center py-2">
              <h2 className="bg-blue-500 w-16 rounded-md">React</h2>
              <h2 className="bg-blue-500 w-16 rounded-md">Tailwind</h2>
              <h2 className="bg-blue-500 w-16 rounded-md">AOS</h2>
            </div>
          </div>

          <div>
            <div
              style={{ backgroundImage: `url(${Advice})` }}
              className=" group container rounded-md flex justify-center items-center mx-auto content-div "
            >
              {/* Hover effect */}

              <div className="opacity-0 group-hover:opacity-100">
                <span className="text-2xl font-bold text-white tracking-wider ">
                  Random Advice
                </span>
                <div className="pt-8 text-center">
                  <a href="https://randomadvice.pages.dev/">
                    <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                      Demo
                    </button>
                  </a>
                  <a href="/">
                    <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                      Code
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div className="flex flex-row space-x-2 text-center py-2">
              <h2 className="bg-blue-500 w-20 rounded-md">Javascript</h2>
              <h2 className="bg-blue-500 w-16 rounded-md">Tailwind</h2>
              <h2 className="bg-blue-500 w-16 rounded-md">API</h2>
            </div>
          </div>

          <div>
            <div
              style={{ backgroundImage: `url(${MealApp})` }}
              className=" group container rounded-md flex justify-center items-center mx-auto content-div "
            >
              {/* Hover effect */}

              <div className="opacity-0 group-hover:opacity-100">
                <span className="text-2xl font-bold text-white tracking-wider ">
                  Search Meal App
                </span>
                <div className="pt-8 text-center">
                  <a href="https://meal-app.pages.dev/">
                    <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                      Demo
                    </button>
                  </a>
                  <a href="/">
                    <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                      Code
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div className="flex flex-row space-x-2 text-center py-2">
              <h2 className="bg-blue-500 w-16 rounded-md">React</h2>
              <h2 className="bg-blue-500 w-16 rounded-md">Tailwind</h2>
              <h2 className="bg-blue-500 w-16 rounded-md">API</h2>
              <h2 className="bg-blue-500 w-20 rounded-md">useContext</h2>
            </div>
          </div>

          <div>
            <div
              style={{ backgroundImage: `url(${FoodApp})` }}
              className=" group container rounded-md flex justify-center items-center mx-auto content-div "
            >
              {/* Hover effect */}

              <div className="opacity-0 group-hover:opacity-100">
                <span className="text-2xl font-bold text-white tracking-wider ">
                  Shopping cart and Google Authentication
                </span>
                <div className="pt-8 text-center">
                  <a href="https://foodapp.pages.dev/">
                    <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                      Demo
                    </button>
                  </a>
                  <a href="/">
                    <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                      Code
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center flex-wrap space-x-2 text-center py-2">
              <h2 className="bg-blue-500 w-16 rounded-md">React</h2>
              <h2 className="bg-blue-500 w-16 rounded-md">Tailwind</h2>

              <h2 className="bg-blue-500 w-32 rounded-md">Redux-toolkit</h2>
              <h2 className="bg-blue-500 w-16 rounded-md">Firebase</h2>
              <h2 className="bg-blue-500 w-32 rounded-md">react-hot-toast</h2>
            </div>
          </div>

          <div
            style={{ backgroundImage: `url(${Advice})` }}
            className=" group container rounded-md flex justify-center items-center mx-auto content-div "
          >
            {/* Hover effect */}

            <div className="opacity-0 group-hover:opacity-100">
              <span className="text-2xl font-bold text-white tracking-wider ">
                Random Advice
              </span>
              <div className="pt-8 text-center">
                <a href="https://1f7c8f91.randomadvice.pages.dev">
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                    Demo
                  </button>
                </a>
                <a href="/">
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url(${Advice})` }}
            className=" group container rounded-md flex justify-center items-center mx-auto content-div "
          >
            {/* Hover effect */}

            <div className="opacity-0 group-hover:opacity-100">
              <span className="text-2xl font-bold text-white tracking-wider ">
                Random Advice
              </span>
              <div className="pt-8 text-center">
                <a href="https://1f7c8f91.randomadvice.pages.dev">
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                    Demo
                  </button>
                </a>
                <a href="/">
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;
